import {
  useCallback,
  useState,
  type ChangeEvent,
  type MouseEventHandler,
} from "react";
import cx from "clsx";
import Nav from "../Nav/nav";
import Logo from "../Logo/logo";
import IconSun from "~icons/bxs/sun";
import IconMoon from "~icons/bxs/moon";
import IconMenu from "~icons/ic/round-menu";
import styles from "./header.module.css";

type Props = {
  currentPath: string;
};

const THEME_KEY = "theme";
const TOP_SWATCH_KEY = "--ts";
const BOTTOM_SWATCH_KEY = "--bs";

function writeToCache(key: string, value: string) {
  const docStyle = getComputedStyle(document.documentElement);
  const cacheName = "soluml";
  const cacheValue = {
    ...document.documentElement.dataset,
    [TOP_SWATCH_KEY]: docStyle.getPropertyValue(TOP_SWATCH_KEY),
    [BOTTOM_SWATCH_KEY]: docStyle.getPropertyValue(BOTTOM_SWATCH_KEY),
    [key]: value,
  };

  localStorage.setItem(cacheName, JSON.stringify(cacheValue));
}

export default function Header({ currentPath }: Props) {
  const [menuOpen, setMenuOpen] = useState(false);

  const onColorChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const { target } = evt;

    if (target.checkValidity()) {
      const name = target.name;

      document.documentElement.style.setProperty(name, target.value);
      writeToCache(name, target.value);
    }
  }, []);

  const onThemeChange = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(() => {
    const name = THEME_KEY;
    const newThemeValue =
      document.documentElement.dataset[name] === "dark" ? "light" : "dark";

    document.documentElement.dataset[name] = newThemeValue;

    writeToCache(name, newThemeValue);
  }, []);

  return (
    <header className={styles.header}>
      <div>
        <a href="#main" className={styles.skipLink}>
          Skip to main content
        </a>

        <div className={styles.logoCnt}>
          <Logo className={styles.logo} />
          <input
            type="color"
            aria-label="Top Swatch"
            name={TOP_SWATCH_KEY}
            onChange={onColorChange}
          />
          <input
            type="color"
            aria-label="Bottom Swatch"
            name={BOTTOM_SWATCH_KEY}
            onChange={onColorChange}
          />
        </div>

        <div className={styles.btnset}>
          <button
            aria-label="Toggle Theme"
            className={styles.themeBtn}
            type="button"
            onClick={onThemeChange}
          >
            {/* @ts-ignore */}
            <IconSun className={styles.themeBtn__light} />
            {/* @ts-ignore */}
            <IconMoon className={styles.themeBtn__dark} />
          </button>
          <hr />
          <button
            aria-label="Toggle Menu"
            aria-pressed={menuOpen.toString() as "true" | "false"}
            className={styles.menuBtn}
            type="button"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <IconMenu />
          </button>
        </div>

        <Nav
          currentPath={currentPath}
          navClass={cx(styles.nav, menuOpen && styles["nav--open"])}
          listClass={styles.list}
        />
      </div>
    </header>
  );
}
