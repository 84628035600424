import { memo } from "react";
import cx from "clsx";
import styles from "./nav.module.css";

type Props = {
  currentPath: string;
  navClass?: string;
  listClass?: string;
};

export const mainLinks = {
  home: {
    name: "Home",
    path: "",
  },
  // blog: {
  //   name: "Blog",
  //   path: "blog",
  // },
  // projects: {
  //   name: "Projects",
  //   path: "projects",
  // },
  // uses: {
  //   name: "What I Use",
  //   path: "uses",
  // },
  contact: {
    name: "Contact",
    path: "contact",
  },
};

export const socialLinks = {
  linkedin: {
    name: "LinkedIn",
    path: "https://www.linkedin.com/in/soluml/",
  },
  github: {
    name: "Github",
    path: "https://github.com/soluml",
  },
  x: {
    name: "Twitter",
    path: "https://x.com/soluml",
  },
  codepen: {
    name: "CodePen",
    path: "https://codepen.io/soluml",
  },
};

function Nav({ currentPath, navClass, listClass }: Props) {
  return (
    <nav className={cx(navClass, styles.nav)}>
      <ul className={cx(listClass, styles.list)} role="list">
        {Object.values(mainLinks).map(({ name, path }) => (
          <li key={name} data-b={name}>
            <a
              aria-current={currentPath === path ? "page" : undefined}
              href={`/${path}`}
            >
              {name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default memo(Nav);
